import store from "../../store";

function getStatusColor(status_id) {
  let status = store.state.config.service.quotes.status_list[status_id];
  // console.log(JSON.parse(JSON.stringify(status_id)));
  if (status) {
    return status.color;
  } else return "inherit";
}

const setBgColor = (el, { value }) => {
  // console.log(value);
  // if (value) {
  el.style.backgroundColor = getStatusColor(value);
  // } else {
  //   console.log("no pe");
  // }
};

export const quoteBgColor = {
  bind: setBgColor,
  update: setBgColor
};
