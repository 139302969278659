// dropdown-item

<template>
  <div class="btn-group w-auto">
    <button
      type="button"
      class="btn btn-light btn-sm dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="disabled"
    >
      <span>{{ status(value).name }}</span>
      <!-- <span> desde las {{time ? time.format('HH:MM') : ''}}</span>  -->
      <span v-show="showLastChangeTime">
        desde las {{ lastChangeTime | timeFormat }}
      </span>
      <!-- <span>{{ text }}</span> -->
    </button>
    <div class="dropdown-menu">
      <template v-for="(l, i) in list">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="valueChild = parseInt(i)"
          :key="i"
          :class="{ active: value == i }"
          v-show="l.selectable"
        >
          {{ l.name }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { myMoment } from "../../utils/myMoment";

export default {
  data: () => ({
    time: null,
    text: ""
  }),
  props: {
    lastChangeTime: {},
    value: {
      default: 1
    },
    disabled: {
      default: false
    }
  },
  watch: {
    valueChild(to) {
      this.$emit("change", to);
    }
  },
  computed: {
    showLastChangeTime() {
      return [3, 4, 4].includes(this.value);
    },
    ...mapState({
      list: s => s.config.service.quotes.status_list
    }),
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        // this.time = myMoment();
        // console.log(val);

        // switch (val) {
        //   case "3":
        //     this.text = " desde las " + myMoment().format("HH:mm");
        //     break;
        //   case "4":
        //     this.text = " desde las " + myMoment().format("HH:mm");
        //     break;
        //   case "5":
        //     this.text = " a las " + myMoment().format("HH:mm");
        //     break;
        //   default:
        //     this.text = "";
        //     break;
        // }
        this.$emit("input", val);
      }
    }
  },
  methods: {
    parseInt,
    status(status_id) {
      return this.list[status_id] ? this.list[status_id] : {};
    }
  }
};
</script>

<style></style>
