<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    @keyup.esc="hide()"
  >
    <div
      class="modal-dialog"
      :class="{
        'modal-lg': lg,
        'modal-xl': xl,
        'modal-dialog-centered': dialogCentered
      }"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ modalTitle }}</h5>
          <button
            @click="hide()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" ref="body">
          <slot></slot>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'

export default {
  data() {
    return {
      /** para guardar valores temporales */
      values: {},
      canSee: false
    };
  },
  routeQuery: {},
  props: {
    modalTitle: {
      default: undefined
    },
    dialogCentered: {
      default: false
    },

    /**
     *
     * keyToRoute
     *
     * Es una clave para agregar en la ruta de la aplicacion de manera que el usuario al
     * presionar el boton atras, cierra el modal :D
     *
     */
    ktr: {
      default: undefined
    },
    lg: {
      default: false
    },
    xl: {
      default: false
    },
    hideIfClickBackground: {
      default: true
    }
  },
  mounted() {
    if (this.hideIfClickBackground) {
      this.$refs.modal.onclick = e => {
        if (e.target.classList.contains("modal")) {
          this.hide();
        }
      };
    }
  },
  watch: {
    canSee(to) {
      if (to) {
        this.$refs.modal.classList.add("show");
        this.$refs.modal.style.display = "block";
        this.$refs.modal.style.overflowY = "auto";
      } else {
        this.$refs.modal.classList.remove("show");
        this.$refs.modal.style.display = "none";
        this.$emit("hidden");
      }
    }
    // $route(to) {
    //   if(this.ktr && this.canSee && !to.query[this.ktr]){
    //     this.canSee = false;
    //   }
    // }
  },
  methods: {
    show() {
      this.canSee = true;

      // if(this.ktr){
      //   this.$options.routeQuery = this.$route.query
      //   let q = {}
      //   q[this.ktr] = 's'
      //   q = {...q, ...this.$route.query}
      //   this.$router.push({query: q})
      // }
    },
    hide() {
      // console.log("Gaaa");
      this.canSee = false;

      // this.$router.replace({
      //   query: this.$options.routeQuery
      // })
    }
  }
};
</script>

<style>
.modal {
  background-color: #00000080;
}
</style>
