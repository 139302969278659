<template>
  <div>
    <quoteForm
      @timeSelected="new_time = $event"
      ref="elForm"
      @changeEmployee="employee_id = $event"
      @employeeInterval="intInterval = $event"
      @changeGroup="$refs.selDur.setGroup($event)"
    >
      <div class="form-group">
        <label for="">Duracion</label>
        <p class="form-control bg-light">
          <i class="fa fa-lock mr-1"></i>
          <span>{{ duration }} </span> Min
        </p>
      </div>
    </quoteForm>
    <!-- <div class="form-group">
      <label for="">Fecha</label>
      <app-datepicker v-model="date" @input="getTimes()"></app-datepicker>
    </div>
    <div class="form-group">
      <label for="">Hora</label>
      <select v-model="new_time" class="custom-select">
        <option v-for="(l, i) in times" :value="l.datetime" :key="i">
          {{ l.datetime }}
        </option>
      </select>
    </div> -->

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="">Nueva Duracion</label>
          <selectDuration
            v-model="duration"
            :iniTime="new_time"
            :employeeId="employee_id"
            :interval="intInterval"
            ref="selDur"
          ></selectDuration>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
// import { ServiceFactory } from "../../service-module/service";
import { myMoment, momentDateTimeFormat } from "../../utils/myMoment";
import quoteForm from "./Form";
import selectDuration from "./select-duration";

export default {
  components: {
    quoteForm,
    selectDuration
  },

  // directives
  // filters

  props: {
    interval: {}
  },

  data: () => ({
    date: new Date(),
    quote_id: 0,
    employee_id: 0,
    duration: 0,
    times: [],
    new_time: "",
    intInterval: 10
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(quote) {
      this.quote_id = quote.id;
      this.employee_id = quote.employee_id;
      this.duration = quote.duration;
      this.$refs.elForm.setPreventDuration(quote.duration);
      this.$refs.elForm.setEmployeeId(quote.employee_id);
      this.$refs.elForm.setGroup(1);
    },
    save() {
      DentalClinicService.saveQuote({
        reprogram_quote: 1,
        id: this.quote_id,
        ini_date: this.new_time,
        group: this.$refs.elForm.getGroup(),
        employee_id: this.employee_id,
        interval: this.interval || this.intInterval,
        end_date: myMoment(this.new_time)
          .add(this.duration, "minute")
          .format(momentDateTimeFormat)
      }).then(() => {
        this.$emit("submitted");
      });
    }
    // getTimes() {
    //   ServiceFactory.scheduleTimes({
    //     employee_id: this.employee_id,
    //     interval: this.duration,
    //     ini_date: this.date,
    //     end_date: this.date,
    //     only_free: 1
    //   }).then(list => {
    //     this.times = list;
    //   });
    // }
  }
};
</script>

<style></style>
