<template>
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      :placeholder="placeholder"
      @keyup="keyUpHandler($event)"
      :name="name"
      v-model="valueChild"
    />
    <div class="input-group-append">
      <button @click.prevent="search()" class="input-group-text btn">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  props: {
    name: {
      default: "app-input-search"
    },
    placeholder: {
      type: String,
      default: "Buscar ..."
    },
    value: {
      default: ""
    }
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    keyUpHandler(e) {
      if (e.keyCode == 13) {
        this.search(e.target.value);
        return;
      }
      this.asyncKeyUpHandler(e.target.value, this);
    },
    asyncKeyUpHandler: debounce((v, vm) => {
      vm.search(v);
    }, 350),
    search() {
      this.$emit("search", this.value);
    }
  }
};
</script>

<style></style>
