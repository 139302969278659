var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("top",[_c('div',{staticClass:"row mb-2"},[_vm._t("tl",[_c('div',{staticClass:"col"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableBtnNew),expression:"!disableBtnNew"}],staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$emit('btnNew')}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Nuevo ")]),_vm._t("afterNewBtn")],2)]),_c('div',{staticClass:"col"},[_c('app-input-search',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableSearch),expression:"!disableSearch"}],on:{"search":function($event){_vm.getListChild();
            _vm.addQueryParam('search', $event);}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2)]),_vm._t("default"),_c('div',{staticClass:"table-responsive bg-white shadow-sm"},[_c('table',{ref:"table",staticClass:"table mb-0",class:{
        'table-sm': _vm.sm,
        'table-bordered': _vm.border
      }},[_vm._t("table")],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > _vm.perPage),expression:"total > perPage"}],staticClass:"row"},[_c('div',{staticClass:"col"},[_c('app-paginate',{ref:"paginate",staticClass:"mt-2 ",class:{ 'd-print-none': !_vm.printPaginate },on:{"change":function($event){_vm.page = $event;
          _vm.gl();
          _vm.addQueryParam('page', $event);}}})],1),_c('div',{staticClass:"col d-flex "},[_c('div',{staticClass:"ml-auto d-none d-md-inline mt-2"},[_c('span',[_vm._v("Total: "+_vm._s(_vm.total))]),_c('span',{staticClass:"ml-2"},[_vm._v("filas")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.perPage),expression:"perPage"}],staticClass:"ml-2 form-control text-right",staticStyle:{"width":"45px","display":"inline"},attrs:{"type":"number"},domProps:{"value":(_vm.perPage)},on:{"change":function($event){_vm.gl();
            _vm.addQueryParam('perPage', $event.target.value);},"input":function($event){if($event.target.composing){ return; }_vm.perPage=$event.target.value}}})])])]),_vm._t("afterpaginate")],2)}
var staticRenderFns = []

export { render, staticRenderFns }